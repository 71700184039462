import { mapState, mapActions } from 'vuex';

import { useDataLoading, usePagination } from '@mixins/factories';

const dataLoadingOptions = { getterName: 'getRoutingRuleUpdateHistory', searchable: false };
const paginationOptions = { itemsName: 'routingRuleUpdateHistory' };
const rulesHistoryHeaders = Object.freeze([
  { text: 'Author', value: 'login' },
  { text: 'Processed Date', value: 'processed' },
  { text: 'Reason', value: 'reason' },
  { text: '', value: 'actions', width: '5%' },
]);
const tabsConfig = Object.freeze([
  { text: 'Update', key: 'update' },
  { text: 'History', key: 'history' },
]);

export default {
  name: 'RoutingRules',
  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
  ],
  data() {
    return {
      tabsConfig,
      rulesHistoryHeaders,
      currentTab: 'update',
      routeRuleCode: '',
      updateReason: '',
    };
  },
  computed: {
    ...mapState('routingRules', [
      'routingRule',
      'routingRuleCode',
      'routingRuleUpdateHistory',
      'routingRuleUpdateHistoryTotalCount',
    ]),
    isInvalidForm() {
      return !this.routeRuleCode || !this.updateReason;
    },
  },
  watch: {
    routingRuleCode: {
      handler(newValue) {
        this.routeRuleCode = newValue;
      },
      immediate: true,
    },
  },
  created() {
    this.getRoutingRule();
  },
  methods: {
    ...mapActions('routingRules', [
      'getRoutingRule', 'updateRoutingRule', 'getRoutingRuleUpdateHistory',
    ]),
    showUpdateRulePrompt() {
      this.$VBlackerTheme.alert.warning({
        text: 'Update routing rule?',
      }, this.updateRule);
    },
    async updateRule() {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.updateRoutingRule({
          rules: this.routeRuleCode,
          reason: this.updateReason,
        });
        this.$VBlackerTheme.notification.success('Routing rule successfully updated');
        this.pageNum = '1';
        this.allowGettingData = true;
        this.updateReason = '';
        this.getRoutingRule();
      } catch (e) {
        this.$VBlackerTheme.notification.error(e);
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
  },
};
